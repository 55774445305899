import styled from 'styled-components';

export const Ul = styled.ul`
  margin-bottom: 1rem;
  list-style: disc inside;
`;

export const Ol = styled.ol`
  margin-bottom: 1rem;
  list-style: decimal inside;
`;

export const ListItem = styled.li`
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-size: 12pt;
  line-height: 1.75;
`;
