import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ListItem, Ul } from '../components/List';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { Typography } from '../components/Typography';
import { Paths } from '../paths';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const Text = styled(Typography)`
  font-size: 12pt;
  line-height: 115%;
  margin-bottom: 1rem;
`;

const Paragraph = styled(Typography)`
  font-size: 12pt;
  line-height: 1.75;
  margin-bottom: 1rem;
`;

const TermsOfUse: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Terms of Use" description="Terms of Use for Hypercite software and image" />
      <Container>
        <Text className="font-bold text-center" underline>
          HyperCite<sup>&reg;</sup> TERMS OF USE
        </Text>

        <Paragraph>
          Welcome to HyperCite<sup>&reg;</sup>. These terms of use and our privacy policy constitute an agreement
          (“Agreement”) between you and HyperCite<sup>&reg;</sup> Inc., its subsidiaries, affiliates, and third-party
          associates (“HyperCite<sup>&reg;</sup>”). HyperCite<sup>&reg;</sup> runs a website that allows users to upload
          and convert legal documents to make a unified electronic file with internal links to the corresponding
          documents (the “Website”). The Agreement applies to anyone (referred to as “users”, “user”, “you”, or “your”
          as applicable) who uses any of the services HyperCite
          <sup>&reg;</sup> offers which include, without limitation, accessing, or using the Website (collectively, the
          “Services”).
        </Paragraph>

        <Paragraph>
          By accessing or using our Services, you acknowledge that you have read this Agreement, including HyperCite
          <sup>&reg;</sup>’ <Link to={Paths.PrivacyPolicy}>Privacy Policy</Link>, understand it, and agree to be bound
          by its terms. If you do not agree, then please do not use the Services.
        </Paragraph>

        <Text className="font-bold">The Effective Date of this Agreement is August 1, 2019.</Text>

        <Text className="font-bold" upper>
          Modifications
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> reserves the right, in its sole discretion, to modify or add to this Agreement at
          any time, for any reason, with or without notice to you (“Updated Terms”). The Updated Terms shall be included
          in a revised version of this Agreement accessible through the Website. HyperCite<sup>&reg;</sup> may provide
          notice of significant changes to this Agreement as required by law.
        </Paragraph>

        <Paragraph>
          Your use of the Services following the posting of any Updated Terms constitutes your unconditional acceptance
          and agreement to be bound by those changes. You must cease using the Services immediately if you do not agree
          to be bound by the Updated Terms.
        </Paragraph>

        <Paragraph>
          The Updated Terms will be effective as of the time of posting, or such later date as may be specified in the
          Updated Terms, and will apply to your use of the Services from that point forward.
        </Paragraph>

        <Text className="font-bold" upper>
          General Conditions
        </Text>

        <Paragraph>You agree to the following conditions for your use of the Services:</Paragraph>

        <Ul>
          <ListItem>
            HyperCite<sup>&reg;</sup> reserves the right to refuse Service to anyone for any reason at any time.
          </ListItem>
          <ListItem>
            You shall be solely responsible for your own Content and the consequences of submitting your Content to the
            Services.
          </ListItem>
          <ListItem>
            You agree that the Content that you submit to the Services will not contain third-party copyrighted material
            or material that is subject to other third-party proprietary rights unless you have permission from the
            rightful owner of the material or you are otherwise legally entitled to use the material in your electronic
            file.
          </ListItem>
        </Ul>

        <Text className="font-bold" upper>
          Prohibites Uses
        </Text>

        <Paragraph>You shall not:</Paragraph>

        <Ul>
          <ListItem>
            Access or try to access non-public areas of the Services, HyperCite<sup>&reg;</sup> computer systems, or the
            technical delivery systems employed by HyperCite
            <sup>&reg;</sup> for its Services;
          </ListItem>
          <ListItem>
            Reverse engineer any aspect of the Services or do anything that might discover source code or bypass or
            circumvent measures employed to prevent or limit access to any area, Content, or code of the Services.
          </ListItem>
          <ListItem>
            Disassemble, decompile, or reverse-engineer HyperCite<sup>&reg;</sup> Website or Services or modify another
            website, application, or product offering so as to falsely imply that it is associated with HyperCite
            <sup>&reg;</sup>.
          </ListItem>
          <ListItem>Transmit any worms or viruses or any code of a destructive nature.</ListItem>
          <ListItem>
            Automate the use of the Services. You must not use or launch any automated system (including, without
            limitation, a computer program, software, bots, or robots) that accesses the Services in an unattended way.
          </ListItem>
          <ListItem>
            Develop any third-party applications that interact with the Services without our prior written consent.
          </ListItem>
          <ListItem>
            Remove, obscure or modify any copyright, trademark or other proprietary rights notices, marks or labels
            contained on or within the Services, falsify or delete any author attributions, legal notices or other
            labels of the origin or source of the material;
          </ListItem>
          <ListItem>
            Use the Services for any illegal or unauthorized purpose or engage in, encourage, or promote any illegal
            activity or any other activity that violates this Agreement or any laws in your jurisdiction.
          </ListItem>
        </Ul>

        <Text className="font-bold" upper>
          Account
        </Text>

        <Text>
          You are required to provide an email address and create a password to establish an account on the Website in
          order to access and use the Services (the “Account”). Each Account and the user identification and password
          for each Account (the “Account ID”) is personal in nature. Each Account is for your personal use and each
          Account ID may be used only by you alone. You are fully responsible for all liabilities and damages incurred
          through the use of your Account or under any Account ID (whether lawful or unlawful) and that any transactions
          completed through any Account or under any Account ID will be deemed to have been lawfully completed by you.
        </Text>

        <Text className="font-bold" upper>
          End User Licenses
        </Text>

        <Paragraph>
          Subject to your compliance with this Agreement, HyperCite<sup>&reg;</sup> will permit you to access and use
          the Services solely for lawful purposes and only in accordance with the terms of this Agreement, and any other
          agreement you may have entered into with Hypercite. HyperCite<sup>&reg;</sup> hereby grants user a
          nonexclusive, nonassignable, non-transferrable, revocable, limited license to access, interface with, interact
          with, and use the Services for the limited purpose of uploading documents, graphics, materials, or other data
          (the “Content”) and synthesizing that Content to create a hyperlinked legal brief (“User Work Product”).
        </Paragraph>

        <Text className="font-bold" upper>
          Your content
        </Text>

        <Paragraph>You retain all of your ownership rights in your Content.</Paragraph>

        <Text className="font-bold" upper>
          Maintenance and support
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> will use its commercially reasonable efforts to: (a) maintain the accessibility of
          the Services on a continuous basis excepting such time periods as are needed for planned server maintenance,
          for network downtime not attributable to or under the control of HyperCite<sup>&reg;</sup> or due to HyperCite
          <sup>&reg;</sup>’ exercise of its rights under this Agreement; and (b) provide User with instruction materials
          for accessing and operating the Services.
        </Paragraph>

        <Text className="font-bold" upper>
          Data collection and use
        </Text>

        <Paragraph>
          <u>Acknowledgement of Our Privacy Policy.</u> You expressly consent to the use and disclosure of personally
          identifiable information and other data and information as described in the Privacy Policy. Notwithstanding
          anything in the Privacy Policy, HyperCite
          <sup>&reg;</sup> shall have the right to collect, extract, compile, synthesize, and analyze non-personally
          identifiable data or information (data or information that does not identify an entity or natural person as
          the source) resulting from your access to and use of the Services. To the extent any such data or information
          is collected or generated by Hypercite, the data and information will be solely owned by HyperCite
          <sup>&reg;</sup> and may be used by HyperCite<sup>&reg;</sup> for any lawful business purpose without a duty
          of accounting to you.
        </Paragraph>

        <Text className="font-bold" upper>
          Termination
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> reserves the right to investigate, terminate, change, suspend, or discontinue the
          Services at its sole discretion, including without limitation, the availability of any feature, database, or
          Content as described below, or your access to all or any part of the Services, including your Account at any
          time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture
          and destruction of all information associated with your use of the Service and related content. Users may also
          terminate this Agreement at any time. However, any obligations and liabilities made by User prior to
          termination of this Agreement shall be strictly enforced.
        </Paragraph>

        <Text className="font-bold" upper>
          Warranties and disclaimers
        </Text>

        <Paragraph>You represent and warrant that:</Paragraph>

        <Ul>
          <ListItem>you have the legal right and authority to enter into this Agreement;</ListItem>
          <ListItem>
            you have the legal right and authority to perform its obligations under this Agreement and to grant the
            rights and licenses described in this Agreement.
          </ListItem>
          <ListItem>you are older than the age of 18</ListItem>
          <ListItem>
            User agrees to review and follow any and all instructions provided by HyperCite
            <sup>&reg;</sup> when creating User Work Product. User acknowledges that results for User Work Product can
            vary significantly. User is solely responsible for reviewing accuracy and function of User Work Product
            before relying on, filing, or using User Work Product in any way.
          </ListItem>
          <ListItem>
            User and HyperCite<sup>&reg;</sup> each acknowledge that the Content and User Work Product may contain
            information relating to private individuals and could contain information protected under the attorney
            client and work product and other privileges. User shall be solely responsible, and hereby releases the
            Hypercite from any liability, for any unauthorized disclosure or other misuse of the User Work Product.
          </ListItem>
        </Ul>

        <Paragraph>
          THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND HyperCite<sup>&reg;</sup> EXPRESSLY DISCLAIMS, AND
          YOU DISCLAIM ANY RELIANCE ON, ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THE
          SUBJECT MATTER OF THIS AGREEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY
          WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE OR NON-INFRINGEMENT. NO ORAL OR WRITTEN
          INFORMATION OR ADVICE GIVEN BY HyperCite<sup>&reg;</sup> WILL INCREASE THE SCOPE OF, OR CREATE ANY NEW
          WARRANTIES IN ADDITION TO, THE WARRANTIES EXPRESSLY SET FORTH IN THIS SECTION. THE COMPANY MAKES NO WARRANTY
          WHATSOEVER THAT THE OPERATION AND AVAILABILITY OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
        </Paragraph>

        <Paragraph>
          User hereby acknowledges and agrees that by providing use and access of the Services to the User, HyperCite
          <sup>&reg;</sup> is not providing any legal services, is not counseling User or any of User’s clients, does
          not verify the accuracy of any citation or link contained in the documents created using the Services, is not
          responsible for the User Work Product including the content of the User Work Product and does not review the
          User Work Product for completeness or accuracy.
        </Paragraph>

        <Text className="font-bold" upper>
          Limitation on liability
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
          CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY OR
          TORT (INCLUDING NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH OR OUT OF THE USE OF THE WEBSITE,
          CONTENT, OR SERVICES, EVEN IF HyperCite<sup>&reg;</sup> HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
          INCLUDING WITHOUT LIMITATION, ANY LOSS OF SUBMISSIONS, OPPORTUNITY, REVENUES OR PROFITS, BUSINESS
          INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES. HyperCite<sup>&reg;</sup>’S TOTAL CUMULATIVE
          LIABILITY IN CONNECTION WITH THIS AGREEMENT AND ALL SERVICES PROVIDED UNDER THIS AGREEMENT, WHETHER IN
          CONTRACT, TORT, OR OTHERWISE, WILL NOT EXCEED THE AMOUNT YOU PAID FOR THE SERVICES. IF YOU USE THE FREE LEVEL
          OF SERVICE, YOU ACKNOWLEDGE THAT, SAVE FOR ANY LIABILITY WE CANNOT EXCLUDE AT LAW, YOUR SOLE REMEDY IS TO
          DISCONTINUE USE OF THE SERVICE. YOU AGREE THAT HyperCite<sup>&reg;</sup>
          WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS ON ITS LIABILITY. IN JURISDICTIONS WHERE
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED, HyperCite<sup>&reg;</sup>’S
          LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </Paragraph>

        <Text className="font-bold" upper>
          Indemnification
        </Text>

        <Paragraph>
          You agree to defend, indemnify, and hold harmless HyperCite<sup>&reg;</sup>, its respective officers,
          directors, agents, and employees from and against any actions, claims, lawsuits, and causes of action, whether
          or not involving a third party, collectively defined as “Claims”, and all damages, awards, penalties,
          liabilities, costs, and expenses, including reasonable attorney’s fees arising out of this Agreement or your
          use of the Services. These Claims may be related to, but are not limited to: (a) any Content submitted or
          posted by you, in connection with the Services, or any use of the Services in violation of this Agreement; (b)
          fraud you commit or your intentional misconduct or gross negligence; or (c) your violation of any applicable
          law or rights of a third-party. HyperCite<sup>&reg;</sup> may select and retain counsel to represent it at
          your own expense. Without limitation, reasonable attorney’s fees and costs shall include attorney’s fees and
          costs required to enforce this indemnification provision. You shall promptly notify HyperCite<sup>&reg;</sup>{' '}
          of any claim or intended suit against HyperCite
          <sup>&reg;</sup>.
        </Paragraph>

        <Text className="font-bold" upper>
          Dispute resolution
        </Text>

        <Paragraph className="font-bold">
          For any and all disputes you may have with HyperCite<sup>&reg;</sup>, you agree to first contact HyperCite
          <sup>&reg;</sup> and attempt to resolve the dispute informally. In the unlikely event that HyperCite
          <sup>&reg;</sup> has not been able to resolve a dispute within 30 days, you and HyperCite<sup>&reg;</sup> each
          agree to resolve any Claim (excluding claims for injunctive or other equitable relief) in connection with the
          Services, including breach or alleged breach of this Agreement, by binding arbitration.
        </Paragraph>

        <Paragraph className="font-bold">
          Unless you and Hypercite decide otherwise, arbitration will be conducted in San Diego, CA. Each party will be
          responsible for paying its own equally proportionate share of any filing, administrative, and arbitrator fees.
          The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees, and
          reasonable costs for experts and other witnesses, and any judgment on the award rendered by the arbitrator may
          be entered in any court of competent jurisdiction. Nothing in this section shall prevent either party from
          seeking injunctive or equitable relief from the courts for matters related to intellectual property rights or
          unauthorized access to the Service.
        </Paragraph>

        <Paragraph className="font-bold">
          TO THE EXTENT PERMITTED BY LAW, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS THE PARTIES AGREE
          OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO
          THESE TERMS, YOU AND HyperCite<sup>&reg;</sup> ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
          IN A CLASS ACTION.
        </Paragraph>

        <Text className="font-bold" upper>
          Force majeure
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> shall be excused from performance under this Agreement to the extent it is prevented
          or delayed from performing, in whole or in part, as a result of an event or series of events caused by or
          resulting from: (a) weather conditions or other elements of nature or acts of God; (b) acts of war, acts of
          terrorism, insurrection, riots, civil disorders, or rebellion; (c) quarantines or embargoes; (d) labor
          strikes; (e) error or disruption to major computer hardware or networks or software failures; or (g) other
          causes beyond the reasonable control of HyperCite<sup>&reg;</sup>.
        </Paragraph>

        <Text className="font-bold" upper>
          Severability
        </Text>

        <Paragraph>
          If any portion of this Agreement is held invalid or unenforceable, it will be so held to the minimum extent
          required by law, and all other terms will remain valid and enforceable. Upon such determination that any term
          is invalid, illegal, or incapable of being enforced, you shall negotiate in good faith with HyperCite
          <sup>&reg;</sup> to modify this Agreement to effect the original intent of the drafters as closely as possible
          to the fullest extent permitted by applicable law.
        </Paragraph>

        <Text className="font-bold" upper>
          Governing law
        </Text>

        <Paragraph>
          This Agreement is governed by the laws of the State of California, without regard to any conflict of laws
          rules or principles. HyperCite<sup>&reg;</sup>’s failure to enforce any right or provision of this Agreement
          shall not be considered a waiver of those rights.
        </Paragraph>

        <Text className="font-bold" upper>
          Questions and contact information
        </Text>

        <Paragraph>
          Please contact HyperCite<sup>&reg;</sup> if you have any questions about this Agreement at:
        </Paragraph>

        <Paragraph link underline>
          <a href="mailto:info@hypercite.net">info@hypercite.net</a>
        </Paragraph>

        <Text className="font-bold" upper>
          Entire Agreement
        </Text>

        <Paragraph>
          This Agreement is the complete and exclusive agreement between you and HyperCite
          <sup>&reg;</sup> regarding your access to and use of the Services. This Agreement supersedes any prior
          agreement or proposal, oral or written, and any other communications between you and HyperCite<sup>&reg;</sup>{' '}
          relating to your use of the Services (except for other agreements you may have or will enter with HyperCite
          <sup>&reg;</sup> for additional premium services). In the event of any conflict between the terms of any such
          agreement and this Agreement, then the terms of this Agreement shall control.
        </Paragraph>
      </Container>
    </PublicRoute>
  );
};

export default TermsOfUse;
